import React, { useMemo, useState } from 'react';

import { useTranslation, useTranslationX } from 'i18n';
import { Grid, Modal, ModalPDFViewer, Row } from 'components/containers';

import { Button, PlainField, Spacer, WrapperCard } from 'components/elements';
import { HiringStatus } from 'pages/project/types';
import { formatDateToBrazilian } from 'utils/stringUtils';
import { getMasked } from 'utils/inputMask';
import { useRequest } from 'apis';
import { Download, Eye } from 'components/icons';
import { TextArea } from 'components/form';

import styles from './execution.module.scss';

type Data = {
  agenciaConta: string;
  banco: string;
  bolsaCargaHorariaMensal: number;
  bolsaDuracao: number;
  bolsaInicio: Date;
  estado: HiringStatus;
  fonte: string;
  gpf: string;
  remuneracao?: number;
  encargos?: number;
  localAtividadesUF: string;
  localAtividadesCEP: string;
  localAtividadesBairro: string;
  localAtividadesCidade: string;
  localAtividadesComplemento: string | null;
  localAtividadesInstituicao: string;
  localAtividadesLocal: string;
  localAtividadesLogradouro: string;
  localAtividadesNumero: string;
  localAtividadesOrgao: string;
  localTrabalho: string | null;
  nascimento: Date;
  nome: string;
  nomeSocial: string;
  numeroConta: string;
  projetoCoordenador: string;
  projetoSigla: string;
  projetoTitulo: string;
  rg: string;
  rgEmissor: string;
  rgEmissorUf: string;
  sexo: string;
  tipo: string;
  titulo: string;
  vinculoContratacao: string;
  vinculoEmpregaticio: boolean;
  valorHora: number;
  termo_id: number;
  avaliacaoBolsista?: Avaliacao[];
  avaliacaoCoordenador?: Avaliacao[];
  isCLT: boolean;
  descricaoAtividades: string;
  resumoAtividades: string;
  cbo: string;
  formacao: string;
  vinculoContratacaoId: number;
  classificacao: string;
  naturalidade: string;
  estadoCivil: string;
  carteiraProfissional: string;
  carteiraProfissionalSerie: string;
  tituloEleitor: string;
  tituloEleitorZona: string;
  tituloEleitorSecao: string;
  pis: string;
  pisDataCadastro: Date;
  dependentes: number;
  cpf?: string;
  justificativa: string;
  iniciativa: string;
  avisoPrevio: string;
  dataDesligamento: Date;
};

type Avaliacao = {
  id: number;
  pergunta: string;
  resposta: string;
};

type RhArchives = {
  docRg: number | null;
  docComprovanteResidencia: number | null;
  docDeclaracaoMatricula: number | null;
  docHistoricoEscolar: number | null;
  docComprovanteContaCorrente: number | null;
  docDescricaoProcessoSeletivo: number | null;
  docSolicitacaoDeContratacaoDeBolsa: number | null;
  docOutorgaEConcessao: number | null;
  docAutorizacaoPolo: number | null;
  docTermoConfidencialidade: number | null;
  docContracheque: number | null;
  docDeclaracaoVencimentos: number | null;
  docDeclaracaoPi: number | null;
  docComprovanteTitulacaoFormacao: number | null;

  docComprovanteEscolaridade: number | null;
  docCurriculo: number | null;
  docCtps: number | null;
  docCpf: number | null;
  docReservista: number | null;
  docTituloEleitor: number | null;
  docFoto: number | null;
  docCertidaoCasamento: number | null;
  docFormularioContratacao: number | null;

  docTermoEstagio: number | null;
};

const Hiring = ({ data }: { data: Data & RhArchives }) => {
  const { tx } = useTranslationX('request', 'project');
  const { tx: tx2 } = useTranslationX('request.form', 'project');
  const { t } = useTranslation();

  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);
  const [openAvaliacaoModal, setOpenAvaliacaoModal] = useState<{
    coordenador: boolean;
    open: boolean;
  }>({
    open: false,
    coordenador: false,
  });

  const {
    titulo,
    projetoTitulo,
    projetoSigla,
    projetoCoordenador,
    tipo,
    fonte,
    remuneracao,
    encargos,
    gpf,
    nascimento,
    avaliacaoBolsista,
    avaliacaoCoordenador,
    nome,
    nomeSocial,
    sexo,
    rg,
    rgEmissor,
    rgEmissorUf,
    banco,
    agenciaConta,
    numeroConta,
    vinculoEmpregaticio,
    localTrabalho,
    localAtividadesInstituicao,
    localAtividadesLocal,
    localAtividadesOrgao,
    localAtividadesLogradouro,
    localAtividadesNumero,
    localAtividadesComplemento,
    localAtividadesBairro,
    localAtividadesCidade,
    bolsaInicio,
    vinculoContratacao,
    classificacao,
    bolsaCargaHorariaMensal,
    bolsaDuracao,
    valorHora,
    termo_id,
    docAutorizacaoPolo,
    docComprovanteContaCorrente,
    docComprovanteResidencia,
    docContracheque,
    docDeclaracaoMatricula,
    docDeclaracaoPi,
    docDeclaracaoVencimentos,
    docDescricaoProcessoSeletivo,
    docHistoricoEscolar,
    docOutorgaEConcessao,
    docRg,
    docSolicitacaoDeContratacaoDeBolsa,
    docComprovanteTitulacaoFormacao,
    docTermoConfidencialidade,
    docCurriculo,
    docCtps,
    docCpf,
    docReservista,
    docTituloEleitor,
    docFoto,
    docCertidaoCasamento,
    docFormularioContratacao,
    docComprovanteEscolaridade,
    docTermoEstagio,
    isCLT,
    resumoAtividades,
    vinculoContratacaoId,
    dataDesligamento,
    justificativa,
    avisoPrevio,
    iniciativa,
  } = data;

  const isIntern = vinculoContratacaoId === 4;

  const request = useRequest();

  const getArchive = (id: number) => {
    request<string>({
      url: `/arquivo/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'string',
      },
      onSuccess: handleDownload,
    });
  };

  const handleDownload = (token: string) => {
    request<any>({
      url: `/resource/${token}`,
      method: 'GET',
      responseType: 'blob',
      onSuccess: (blob: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
        };
      },
      onError: data => console.error(data),
    });
  };

  const archives = useMemo<RhArchives>(() => {
    return {
      docRg,
      docComprovanteResidencia,
      docDeclaracaoMatricula,
      docHistoricoEscolar,
      docComprovanteContaCorrente,
      docDescricaoProcessoSeletivo,
      docSolicitacaoDeContratacaoDeBolsa,
      docOutorgaEConcessao,
      docAutorizacaoPolo,
      docTermoConfidencialidade,
      docContracheque,
      docDeclaracaoVencimentos,
      docDeclaracaoPi,
      docComprovanteTitulacaoFormacao,
      docCurriculo,
      docCtps,
      docCpf,
      docReservista,
      docTituloEleitor,
      docFoto,
      docCertidaoCasamento,
      docFormularioContratacao,
      docComprovanteEscolaridade,
      docTermoEstagio,
    };
  }, [
    docRg,
    docAutorizacaoPolo,
    docComprovanteContaCorrente,
    docComprovanteResidencia,
    docContracheque,
    docDeclaracaoMatricula,
    docDescricaoProcessoSeletivo,
    docDeclaracaoPi,
    docDeclaracaoVencimentos,
    docOutorgaEConcessao,
    docHistoricoEscolar,
    docSolicitacaoDeContratacaoDeBolsa,
    docTermoConfidencialidade,
    docComprovanteTitulacaoFormacao,
    docCurriculo,
    docComprovanteEscolaridade,
    docCtps,
    docCpf,
    docReservista,
    docTituloEleitor,
    docFoto,
    docCertidaoCasamento,
    docFormularioContratacao,
    docTermoEstagio,
  ]);

  const uploadedArchives: RhArchives = useMemo(() => {
    const archivesArray = Object.entries(archives);

    return archivesArray.reduce((acc, [key, value]) => {
      if (value !== null) {
        acc[key as keyof RhArchives] = value;
      }
      return acc;
    }, {} as RhArchives);
  }, [archives]);

  const handleClose = () => {
    setPdfData(null);
  };

  return (
    <Grid spacing={0} maxWidth="800px">
      <Row width={[3, 3]} align="top">
        <PlainField label={tx('form.itemName')} value={titulo} />
        <PlainField label={tx('list.requestType')} value={vinculoContratacao} />
        <PlainField label={tx('form.classificacao')} value={classificacao} />
      </Row>
      <Row width={[3, 3]} align="top">
        <PlainField label={tx('form.projectTitle')} value={projetoTitulo} />
        <PlainField label={tx('form.projectInitials')} value={projetoSigla} />
      </Row>
      <Row width={[3, 3]} align="top">
        <PlainField label={tx('form.projectCoordinator')} value={projetoCoordenador} />
      </Row>
      <Row width={[3, 3]} align="top">
        <PlainField label={tx('list.source')} value={fonte} />
        <PlainField label={tx('list.gpf')} value={gpf} />
      </Row>

      {tipo === 'desligamento' && isCLT && (
        <>
          <Row width={[3, 3]} align="top">
            <PlainField
              label={tx('form.dataDesligamento')}
              value={formatDateToBrazilian(dataDesligamento)}
            />
            <PlainField label={tx('form.avisoPrevio')} value={avisoPrevio} />
          </Row>
          <div style={{ marginLeft: 10 }}>
            <TextArea
              name="justificativa"
              minRows={3}
              value={justificativa}
              type={'readonly'}
              label={tx('form.justificativa.label')}
            />
          </div>
          <Row>
            <PlainField label={tx('form.iniciativa')} value={iniciativa} />
          </Row>
        </>
      )}

      {!isCLT ? (
        <>
          <Row width={[3, 3]} align="top">
            <PlainField label={tx('form.name.label')} value={nome} />
            <PlainField label={tx('form.socialName.label')} value={nomeSocial} />
            <PlainField
              label={tx('form.birth')}
              value={formatDateToBrazilian(nascimento)}
            />
            <PlainField label={tx('form.sex.label')} value={tx(`form.sex.${sexo}`)} />
          </Row>
          <Row width={[3, 3]} align="top">
            <PlainField label={tx('form.rg')} value={rg} />
            <PlainField label={tx('form.orgaoEmissor')} value={rgEmissor} />
            <PlainField label={tx('form.uf')} value={rgEmissorUf} />
          </Row>
          <Row width={[3, 3]} align="top">
            <PlainField label={tx('form.bank')} value={banco} />
            <PlainField label={tx('form.agency')} value={agenciaConta} />
            <PlainField label={tx('form.cc')} value={numeroConta} />
          </Row>
          <Spacer length={20} />
          <Row width={[3, 3]} align="top">
            <PlainField
              label={tx('form.vinculo')}
              value={tx(`boolean.${Number(vinculoEmpregaticio)}`)}
            />
            <PlainField label={tx('form.workplace')} value={localTrabalho || tx('na')} />
          </Row>
          <Spacer length={20} />
          <Row width={[3, 3]} align="top">
            <PlainField
              label={tx('form.institution')}
              value={localAtividadesInstituicao}
            />
            <PlainField label={tx('form.orgao')} value={localAtividadesOrgao} />
            <PlainField label={tx('form.place')} value={localAtividadesLocal} />
          </Row>
          <Spacer length={20} />
          <Row width={[3, 3]} align="top">
            <PlainField label={tx('form.street')} value={localAtividadesLogradouro} />
            <PlainField label={tx('form.number')} value={localAtividadesNumero} />
            <PlainField
              label={tx('form.complement')}
              value={localAtividadesComplemento || tx('na')}
            />
          </Row>
          <Row width={[3, 3]} align="top">
            <PlainField label={tx('form.district')} value={localAtividadesBairro} />
            <PlainField label={tx('form.city')} value={localAtividadesCidade} />
          </Row>
          <Row>
            <PlainField label={tx('form.activitiesResume')} value={resumoAtividades} />
          </Row>

          <Spacer length={20} />
          <Row width={[3, 3]} align="top">
            <PlainField
              label={isIntern ? tx('form.internStart') : tx('form.scholarshipStart')}
              value={formatDateToBrazilian(bolsaInicio)}
            />
            <PlainField
              label={tx('form.monthlyWorkload')}
              value={`${bolsaCargaHorariaMensal} ${tx('form.hourspermonth')}`}
            />
            <PlainField
              label={tx('form.durationMonths')}
              value={bolsaDuracao.toFixed(0)}
            />
          </Row>
        </>
      ) : (
        <CLTFields data={data} />
      )}
      <Spacer length={20} />
      {!isCLT && (
        <Row width={[3, 3]} align="top">
          <PlainField
            label={tx('form.valueHour')}
            value={`${t('currency')} ${getMasked(valorHora.toFixed(2), {
              pattern: 'currency',
            })}`}
          />
          {remuneracao ? (
            <PlainField
              label={tx('form.remuneration')}
              value={`${t('currency')} ${getMasked(remuneracao.toFixed(2), {
                pattern: 'currency',
              })}`}
            />
          ) : null}
          {encargos ? (
            <PlainField
              label={tx('form.encargos')}
              value={`${t('currency')} ${getMasked(encargos.toFixed(2), {
                pattern: 'currency',
              })}`}
            />
          ) : null}
        </Row>
      )}
      {termo_id && (
        <Row width={[3, 3]} align="top">
          <Button theme="light" icon={Eye} onClick={() => getArchive(termo_id)}>
            {tx('form.term')}
          </Button>
        </Row>
      )}

      <div className={styles.documents}>
        {uploadedArchives && tipo === 'rh' && (
          <Row align="top">
            <span className={styles.documentsTitle}>{tx2('uploadedDocuments')}</span>
          </Row>
        )}
        <div className={styles.uploadedDocuments}>
          {uploadedArchives &&
            tipo === 'rh' &&
            Object.keys(uploadedArchives).map(archive => (
              <Row width={[3]} align="top" key={archive}>
                <div className={styles.document}>
                  <Download size={1.5} />
                  <span
                    onClick={() =>
                      getArchive(uploadedArchives[archive as keyof RhArchives] as number)
                    }
                  >
                    <strong>{`${tx2(`${archive}`)}`} </strong>
                  </span>
                </div>
              </Row>
            ))}
        </div>
      </div>

      {tipo === 'desligamento' && avaliacaoBolsista && avaliacaoCoordenador && (
        <>
          <Row width={[3, 3]} align="top">
            <Button
              theme="light"
              onClick={() => {
                setOpenAvaliacaoModal(() => ({
                  open: true,
                  coordenador: true,
                }));
              }}
              icon={Eye}
            >
              {tx('form.avaliacaoCoordenador')}
            </Button>
            <Button
              theme="light"
              onClick={() => {
                setOpenAvaliacaoModal(() => ({
                  open: true,
                  coordenador: false,
                }));
              }}
              icon={Eye}
            >
              {tx('form.avaliacaoBolsista')}
            </Button>
          </Row>

          <Modal
            visible={openAvaliacaoModal?.open}
            width="auto"
            onBlur={() => setOpenAvaliacaoModal({ open: false, coordenador: false })}
            onClose={() => setOpenAvaliacaoModal({ open: false, coordenador: false })}
          >
            <div className={styles.modalContent}>
              <WrapperCard maxWidth="600px">
                {openAvaliacaoModal.coordenador ? (
                  <div className={styles.avaliacao}>
                    <div className={styles.content}>
                      <span className={styles.titulo}>
                        {tx('form.respostas') + tx('form.avaliacaoCoordenador')}
                      </span>
                      {avaliacaoCoordenador?.map(item => (
                        <Row align="top" key={item.id}>
                          <div className={styles.item}>
                            <span className={styles.pergunta}>{item.pergunta}</span>
                            <span className={styles.resposta}>{item.resposta}</span>
                          </div>
                        </Row>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className={styles.avaliacao}>
                    <div className={styles.content}>
                      <span className={styles.titulo}>
                        {tx('form.respostas') + tx('form.avaliacaoBolsista')}
                      </span>
                      {avaliacaoBolsista?.map(item => (
                        <Row align="top" key={item.id}>
                          <div className={styles.item}>
                            <span className={styles.pergunta}>{item.pergunta}</span>
                            <span className={styles.resposta}>{item.resposta}</span>
                          </div>
                        </Row>
                      ))}
                    </div>
                  </div>
                )}
              </WrapperCard>
            </div>
          </Modal>
        </>
      )}

      <Modal
        visible={pdfData !== null}
        width="auto"
        onBlur={handleClose}
        onClose={handleClose}
      >
        <div>
          <ModalPDFViewer pdfData={pdfData} title={tx('archive')} onClose={handleClose} />
        </div>
      </Modal>
    </Grid>
  );
};

const CLTFields = ({ data }: { data: Data & RhArchives }) => {
  const { tx } = useTranslationX('request', 'project');
  const { t } = useTranslation();

  const {
    nome,
    nomeSocial,
    nascimento,
    cpf,
    naturalidade,
    estadoCivil,
    sexo,
    rg,
    rgEmissor,
    rgEmissorUf,
    formacao,
    carteiraProfissional,
    carteiraProfissionalSerie,
    tituloEleitor,
    tituloEleitorSecao,
    tituloEleitorZona,
    pisDataCadastro,
    pis,
    dependentes,
    bolsaInicio,
    cbo,
    descricaoAtividades,
    bolsaCargaHorariaMensal,
    valorHora,
    remuneracao,
    encargos,
    agenciaConta,
    numeroConta,
    banco,

    localAtividadesCEP,
    localAtividadesUF,
    localAtividadesBairro,
    localAtividadesCidade,
    localAtividadesLogradouro,
    localAtividadesNumero,
    localAtividadesComplemento,
  } = data;

  return (
    <>
      <Spacer length={10} />

      <Row align="top">
        <span className={styles.rowLabel}>{tx('sectionPersonalData')}</span>
      </Row>
      <Row width={[3, 3]} align="top">
        <PlainField label={tx('form.name.label')} value={nome} />
        <PlainField label={tx('form.socialName.label')} value={nomeSocial} />

        <PlainField label={tx('form.birth')} value={formatDateToBrazilian(nascimento)} />

        <PlainField label={tx('form.naturalidade')} value={naturalidade} />
      </Row>
      <Row width={[3, 3]} align="top">
        <PlainField
          label={tx('form.grauInstrucao.title')}
          value={tx(`form.grauInstrucao.${formacao}`)}
        />
        <PlainField
          label={tx('form.carteiraProfissional')}
          value={carteiraProfissional}
        />
        <PlainField
          label={tx('form.carteiraProfissionalSerie')}
          value={carteiraProfissionalSerie}
        />
      </Row>
      <Row width={[3, 3]} align="top">
        {cpf && <PlainField label={tx('form.cpf')} value={cpf as string} />}
        <PlainField
          label={tx('form.estadoCivil.title')}
          value={tx('form.estadoCivil.' + estadoCivil)}
        />
        <PlainField label={tx('form.sex.label')} value={tx(`form.sex.${sexo}`)} />
      </Row>
      <Row width={[3, 3]} align="top">
        <PlainField label={tx('form.rg')} value={rg} />
        <PlainField label={tx('form.orgaoEmissor')} value={rgEmissor} />
        <PlainField label={tx('form.uf')} value={rgEmissorUf} />
      </Row>
      <Row width={[3, 3]} align="top">
        <PlainField label={tx('form.tituloEleitor')} value={tituloEleitor} />
        <PlainField label={tx('form.tituloEleitorZona')} value={tituloEleitorZona} />
        <PlainField label={tx('form.tituloEleitorSecao')} value={tituloEleitorSecao} />
      </Row>
      <Row width={[3, 3]} align="top">
        <PlainField label={tx('form.dependentes')} value={String(dependentes)} />
        <PlainField label={tx('form.pis')} value={pis} />
        <PlainField
          label={tx('form.pisDataCadastro')}
          value={formatDateToBrazilian(pisDataCadastro)}
        />
      </Row>

      <Spacer length={10} />
      <Row align="top">
        <span className={styles.rowLabel}>{tx('sectionAddress')}</span>
      </Row>

      <Row width={[3, 3]} align="top">
        <PlainField label={tx('form.cep')} value={localAtividadesCEP} />
        <PlainField label={tx('form.street')} value={localAtividadesLogradouro} />
        <PlainField label={tx('form.number')} value={localAtividadesNumero} />
      </Row>
      <Row width={[3, 3]} align="top">
        <PlainField label={tx('form.district')} value={localAtividadesBairro} />
        <PlainField label={tx('form.city')} value={localAtividadesCidade} />
        <PlainField label={tx('form.uf')} value={localAtividadesUF} />
      </Row>

      <Row width={[1]} align="top">
        <PlainField
          label={tx('form.complement')}
          value={localAtividadesComplemento || tx('na')}
        />
      </Row>

      <Spacer length={10} />
      <Row align="top">
        <span className={styles.rowLabel}>{tx('sectionRole')}</span>
      </Row>
      <Row width={[3, 3]} align="top">
        <PlainField label={tx('form.cbo')} value={cbo} />
        <PlainField
          label={tx('form.dataAdmissao')}
          value={formatDateToBrazilian(bolsaInicio)}
        />
        <PlainField
          label={tx('form.monthlyWorkload')}
          value={`${bolsaCargaHorariaMensal} ${tx('form.hourspermonth')}`}
        />
      </Row>
      <Row width={[3, 3]} align="top">
        <PlainField
          label={tx('form.valueHour')}
          value={`${t('currency')} ${getMasked(valorHora.toFixed(2), {
            pattern: 'currency',
          })}`}
        />
        {remuneracao ? (
          <PlainField
            label={tx('form.remuneration')}
            value={`${t('currency')} ${getMasked(remuneracao.toFixed(2), {
              pattern: 'currency',
            })}`}
          />
        ) : null}
        <PlainField
          label={tx('form.encargos')}
          value={`${t('currency')} ${getMasked(encargos?.toFixed(2) as string, {
            pattern: 'currency',
          })}`}
        />
      </Row>
      <div style={{ marginLeft: 10 }}>
        <TextArea
          name="descricaoAtividades"
          minRows={3}
          value={descricaoAtividades}
          type={'readonly'}
          label={tx('form.activitiesDescription')}
        />
      </div>

      <Spacer length={10} />
      <Row align="top">
        <span className={styles.rowLabel}>{tx('sectionBankData')}</span>
      </Row>

      <Row width={[3, 3]} align="top">
        <PlainField label={tx('form.banco')} value={banco} />
        <PlainField label={tx('form.agencia')} value={agenciaConta} />
        <PlainField label={tx('form.conta')} value={numeroConta} />
      </Row>

      <Spacer length={20} />
    </>
  );
};

export default Hiring;
