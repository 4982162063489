const AlignCenterVertical = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 8.25H17.5V3.75C17.5 3.35218 17.342 2.97064 17.0607 2.68934C16.7794 2.40804 16.3978 2.25 16 2.25H12.25C11.8522 2.25 11.4706 2.40804 11.1893 2.68934C10.908 2.97064 10.75 3.35218 10.75 3.75V8.25H9.25V1.5C9.25 1.10218 9.09196 0.720644 8.81066 0.43934C8.52936 0.158035 8.14782 0 7.75 0H4C3.60218 0 3.22064 0.158035 2.93934 0.43934C2.65804 0.720644 2.5 1.10218 2.5 1.5V8.25H1C0.801088 8.25 0.610322 8.32902 0.46967 8.46967C0.329018 8.61032 0.25 8.80109 0.25 9C0.25 9.19891 0.329018 9.38968 0.46967 9.53033C0.610322 9.67098 0.801088 9.75 1 9.75H2.5V16.5C2.5 16.8978 2.65804 17.2794 2.93934 17.5607C3.22064 17.842 3.60218 18 4 18H7.75C8.14782 18 8.52936 17.842 8.81066 17.5607C9.09196 17.2794 9.25 16.8978 9.25 16.5V9.75H10.75V14.25C10.75 14.6478 10.908 15.0294 11.1893 15.3107C11.4706 15.592 11.8522 15.75 12.25 15.75H16C16.3978 15.75 16.7794 15.592 17.0607 15.3107C17.342 15.0294 17.5 14.6478 17.5 14.25V9.75H19C19.1989 9.75 19.3897 9.67098 19.5303 9.53033C19.671 9.38968 19.75 9.19891 19.75 9C19.75 8.80109 19.671 8.61032 19.5303 8.46967C19.3897 8.32902 19.1989 8.25 19 8.25ZM7.75 16.5H4V1.5H7.75V16.5ZM16 14.25H12.25V3.75H16V14.25Z"
        fill="#5D5A71"
      />
    </svg>
  );
};

export default AlignCenterVertical;
