import React, { ElementType, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useRequest } from 'apis';
import { useTranslation, useTranslationX } from 'i18n';
import { ErrorMessage, Spacer } from 'components/elements';
import { Button } from 'components/elements';
import { AddCheck, Download, Eye, Upload, XRect } from 'components/icons';
import { Data, ScrollPanel } from 'components/containers';
import { renderComponent } from 'utils/components';
import { setNotificationCount } from 'store/profile';
import { useForceUpdate } from 'components/hooks';
import { Dispatch, NotificationContent, NotificationData, State } from './types';
import store from 'store';

import * as register from './register';

import * as updating from './updating';
import * as projectExecution from './project/execution';
import * as projectCounterpart from './project/counterpart';
import * as projectMilestone from './project/milestone';

import * as project from './project';
import * as alterationPT from './project/alterationPT';
import Proposal from './proposal/Proposal';

import banner from 'assets/images/office.jpg';

import styles from './Content.module.scss';

const view: { [key: string]: React.ElementType<any> | undefined } = {
  'cadastro.atualizacao': updating.Wrapper,
  'cadastro.aluno': register.Student,
  'cadastro.pesquisador': register.Researcher,
  'cadastro.colaborador': register.Contributor,
  'cadastro.empresa': register.Company,
  'proposta.analise': Proposal,
  'project.execution.acquisition': projectExecution.Acquisition,
  'project.execution.hiring': projectExecution.Hiring,
  'project.execution.payment': projectExecution.Payment,
  'project.execution.transfer': projectExecution.Transfer,
  'project.execution.extension': projectExecution.Payment,
  'project.execution.vacation': projectExecution.Vacation,
  'project.execution.termination': projectExecution.Hiring,
  'project.milestones.accept': projectMilestone.Accept,
  'project.counterpart.hiring': projectCounterpart.Counterpart,
  'project.counterpart.update': projectCounterpart.Counterpart,
  'project.counterpart.termination': projectCounterpart.Counterpart,
  'project.counterpart.month': projectCounterpart.Month,
  'project.alteration-pt': alterationPT.AlterationPT,
};

type Props = {
  state: State;
  dispatch: Dispatch;
};

const Content = ({ state, dispatch }: Props) => {
  const { status, notifications, selected, content, modal, error } = state;

  const request = useRequest();
  const history = useHistory();

  const { tx: t1 } = useTranslationX('homologacao', 'supervisor');
  const { tx: t2 } = useTranslationX('notification');
  const { t } = useTranslation('translation');

  const update = useForceUpdate();
  const reloadContent = useCallback(
    (data: { id: any; type: any }) => {
      const { id, type: tipo } = data;

      const source = request<NotificationContent>({
        url: `/homologacao/notificacao/${id}`,
        params: { tipo },
        onSuccess: data => {
          update();
          dispatch({ type: 'CONTENT_UPDATE', payload: data });
        },
        onError: value => dispatch({ type: 'ERROR', payload: { key: 'content', value } }),
      });

      return () => source.cancel();
    },
    [dispatch, request, update]
  );

  useEffect(() => {
    if (status === 'loading_data' && selected) {
      reloadContent(selected);
    }
  }, [dispatch, request, selected, status, reloadContent]);

  useEffect(() => {
    const source = request<number>({
      url: '/homologacao/unread',
      onSuccess: data => {
        store.dispatch(setNotificationCount(data));
      },
    });

    return () => source.cancel();
  }, [dispatch, request, state.content]);

  const renderControls = ({
    header: { type, granted },
    body: { projetoId, aditivo },
  }: NotificationContent) => {
    const makeButton = (
      icon: ElementType,
      key: string,
      action: () => void,
      buttonProps?:
        | {
            root?: React.CSSProperties | undefined;
            text?: React.CSSProperties | undefined;
          }
        | undefined
    ) => (
      <Button
        theme="light"
        icon={icon}
        type={granted ? undefined : 'disabled'}
        onClick={action}
        style={buttonProps}
      >
        {t1(`button.${key}`)}
      </Button>
    );

    const updateWpPath = aditivo ? 'aditivo' : 'alteracao-pt';

    switch (type) {
      case 'cadastro.aluno':
      case 'cadastro.pesquisador':
      case 'cadastro.colaborador':
      case 'cadastro.empresa':
        return (
          <>
            {makeButton(AddCheck, 'accept', () =>
              dispatch({
                type: 'MODAL_OPEN',
                payload: {
                  component: register.ConfirmDialog,
                  props: { accept: true, needFile: type !== 'cadastro.empresa' },
                },
              })
            )}
            <Spacer length={27} />
            {makeButton(XRect, 'refuse', () =>
              dispatch({
                type: 'MODAL_OPEN',
                payload: {
                  component: register.ConfirmDialog,
                  props: { accept: false, needFile: type !== 'cadastro.empresa' },
                },
              })
            )}
          </>
        );

      case 'cadastro.atualizacao':
        return (
          <>
            {makeButton(AddCheck, 'accept', () =>
              dispatch({
                type: 'MODAL_OPEN',
                payload: {
                  component: updating.ConfirmDialog,
                  props: { accept: true },
                },
              })
            )}
            <Spacer length={27} />
            {makeButton(XRect, 'refuse', () =>
              dispatch({
                type: 'MODAL_OPEN',
                payload: {
                  component: updating.ConfirmDialog,
                  props: { accept: false },
                },
              })
            )}
          </>
        );

      case 'proposta.analise':
        return makeButton(Eye, 'proposal', () =>
          history.push(`/dashboard/propostas/${selected?.id}`)
        );

      case 'project.execution.acquisition':
        if (content?.body?.estado === 'REQ') {
          return (
            <>
              {makeButton(AddCheck, 'confirm', () =>
                dispatch({
                  type: 'MODAL_OPEN',
                  payload: {
                    component: project.ConfirmDialog,
                    props: {
                      accept: true,
                      needFile: false,
                      execution: true,
                      valorExecutado: content?.body?.valor,
                    },
                  },
                })
              )}
            </>
          );
        }
        return (
          <>
            {makeButton(AddCheck, 'accept', () =>
              dispatch({
                type: 'MODAL_OPEN',
                payload: {
                  component: project.ConfirmDialog,
                  props: { accept: true, needFile: false },
                },
              })
            )}
            <Spacer length={27} />
            {makeButton(XRect, 'refuse', () =>
              dispatch({
                type: 'MODAL_OPEN',
                payload: {
                  component: project.ConfirmDialog,
                  props: { accept: false, needFile: false },
                },
              })
            )}
          </>
        );

      case 'project.execution.payment':
        if (content?.body?.estado === 'REQ') {
          return (
            <>
              {makeButton(AddCheck, 'confirm', () =>
                dispatch({
                  type: 'MODAL_OPEN',
                  payload: {
                    component: project.ConfirmDialog,
                    props: {
                      accept: true,
                      needFile: false,
                      execution: true,
                      valorExecutado: content?.body?.valor,
                    },
                  },
                })
              )}
            </>
          );
        }
        return (
          <>
            {makeButton(AddCheck, 'accept', () =>
              dispatch({
                type: 'MODAL_OPEN',
                payload: {
                  component: project.ConfirmDialog,
                  props: { accept: true, needFile: false },
                },
              })
            )}
            <Spacer length={27} />
            {makeButton(XRect, 'refuse', () =>
              dispatch({
                type: 'MODAL_OPEN',
                payload: {
                  component: project.ConfirmDialog,
                  props: { accept: false, needFile: false },
                },
              })
            )}
          </>
        );
      case 'project.execution.extension':
        if (content?.body?.estado === 'REQ') {
          return (
            <>
              {makeButton(AddCheck, 'confirm', () =>
                dispatch({
                  type: 'MODAL_OPEN',
                  payload: {
                    component: project.ConfirmDialog,
                    props: {
                      accept: true,
                      needFile: false,
                      execution: true,
                      valorExecutado: content?.body?.valor,
                    },
                  },
                })
              )}
            </>
          );
        }
        return (
          <>
            {makeButton(AddCheck, 'accept', () =>
              dispatch({
                type: 'MODAL_OPEN',
                payload: {
                  component: project.ConfirmDialog,
                  props: { accept: true, needFile: false },
                },
              })
            )}
            <Spacer length={27} />
            {makeButton(XRect, 'refuse', () =>
              dispatch({
                type: 'MODAL_OPEN',
                payload: {
                  component: project.ConfirmDialog,
                  props: { accept: false, needFile: false },
                },
              })
            )}
          </>
        );

      case 'project.alteration-pt':
        if (content?.body.estado === 'ANC') {
          return (
            <>
              {makeButton(AddCheck, 'accept', () =>
                dispatch({
                  type: 'MODAL_OPEN',
                  payload: {
                    component: project.ConfirmDialog,
                    props: { accept: true, needFile: false, isWpCancel: true },
                  },
                })
              )}
              <Spacer length={27} />
              {makeButton(XRect, 'refuse', () =>
                dispatch({
                  type: 'MODAL_OPEN',
                  payload: {
                    component: project.ConfirmDialog,
                    props: { accept: false, needFile: false },
                  },
                })
              )}
            </>
          );
        }
        return makeButton(Eye, aditivo ? 'project.additive-pt' : type, () =>
          history.push(`/dashboard/projetos/${projetoId}/${updateWpPath}`)
        );

      case 'project.execution.hiring':
        if (content?.body?.estado === 'REQ') {
          return (
            <>
              {makeButton(AddCheck, 'confirm', () =>
                dispatch({
                  type: 'MODAL_OPEN',
                  payload: {
                    component: project.ConfirmDialog,
                    props: {
                      accept: true,
                      needFile: false,
                      execution: true,
                      needValorExecutado: false,
                      valorExecutado: content?.body?.valor,
                    },
                  },
                })
              )}
            </>
          );
        }

        if (content?.body.estado === 'ANC') {
          return (
            <>
              {makeButton(AddCheck, 'accept', () =>
                dispatch({
                  type: 'MODAL_OPEN',
                  payload: {
                    component: project.ConfirmDialog,
                    props: { accept: true, needFile: false, isWpCancel: true },
                  },
                })
              )}
              <Spacer length={27} />
              {makeButton(XRect, 'refuse', () =>
                dispatch({
                  type: 'MODAL_OPEN',
                  payload: {
                    component: project.ConfirmDialog,
                    props: { accept: false, needFile: false },
                  },
                })
              )}
            </>
          );
        }

        const isInternship = content?.body.vinculoContratacaoId === 4;
        const hasInternshipDoc = !!content?.body.docTermoEstagio;

        return (
          <>
            {content?.body.isCLT &&
              makeButton(
                Download,
                'downloadFormularioContratacao',
                () => {
                  dispatch({
                    type: 'MODAL_OPEN',
                    payload: {
                      component: projectExecution.HiringDonwloadDeclaration,
                      props: {
                        accept: true,
                        needFile: false,
                        content: content?.body,
                        reload: reloadContent,
                        dispatch,
                        path: 'formulario-contratacao',
                      },
                    },
                  });
                },
                {
                  root: {
                    maxWidth: '300px',
                  },
                }
              )}
            {content?.body.vinculoContratacaoId === 1 && (
              <>
                {makeButton(
                  Download,
                  'downloadAutorization',
                  () => {
                    dispatch({
                      type: 'MODAL_OPEN',
                      payload: {
                        component: projectExecution.HiringDonwloadDeclaration,
                        props: {
                          accept: true,
                          needFile: false,
                          content: content?.body,
                          reload: reloadContent,
                          dispatch,
                          path: 'autorizacao',
                        },
                      },
                    });
                  },
                  {
                    root: {
                      maxWidth: '200px',
                    },
                  }
                )}
                {makeButton(
                  Upload,
                  'uploadDocuments',
                  () => {
                    dispatch({
                      type: 'MODAL_OPEN',
                      payload: {
                        component: projectExecution.HiringDocsDialog,
                        props: {
                          accept: true,
                          needFile: false,
                          content: content?.body,
                          reload: reloadContent,
                          dispatch,
                        },
                      },
                    });
                  },
                  {
                    root: {
                      maxWidth: '200px',
                    },
                  }
                )}
              </>
            )}
            {(content?.body.vinculoContratacaoId !== 1 ||
              content?.body.docAutorizacaoPolo) &&
              makeButton(AddCheck, 'accept', () =>
                dispatch({
                  type: 'MODAL_OPEN',
                  payload: {
                    component: project.ConfirmDialog,
                    props: {
                      accept: true,
                      needFile: false,
                      isInternship: isInternship && hasInternshipDoc,
                      internshipData: {
                        startDate: content?.body.bolsaInicio,
                        hourlyRate: content?.body.bolsaCargaHorariaMensal,
                        duration: content?.body.bolsaDuracao,
                        remuneration: content?.body.remuneracao,
                      },
                    },
                  },
                })
              )}
            <Spacer length={27} />
            {makeButton(XRect, 'refuse', () =>
              dispatch({
                type: 'MODAL_OPEN',
                payload: {
                  component: project.ConfirmDialog,
                  props: {
                    accept: false,
                    needFile: false,
                  },
                },
              })
            )}
          </>
        );
      case 'project.milestones.accept':
      case 'project.counterpart.hiring':
      case 'project.counterpart.month':
      case 'project.counterpart.update':
      case 'project.counterpart.termination':
      case 'project.execution.transfer':
      case 'project.execution.termination':
      case 'project.execution.vacation':
        if (content?.body?.estado === 'REQ') {
          return (
            <>
              {makeButton(AddCheck, 'confirm', () =>
                dispatch({
                  type: 'MODAL_OPEN',
                  payload: {
                    component: project.ConfirmDialog,
                    props: {
                      accept: true,
                      needFile: false,
                      execution: true,
                      needValorExecutado: false,
                      valorExecutado: content?.body?.valor,
                    },
                  },
                })
              )}
            </>
          );
        }

        return (
          <>
            {makeButton(AddCheck, 'accept', () =>
              dispatch({
                type: 'MODAL_OPEN',
                payload: {
                  component: project.ConfirmDialog,
                  props: { accept: true, needFile: false },
                },
              })
            )}
            <Spacer length={27} />
            {makeButton(XRect, 'refuse', () =>
              dispatch({
                type: 'MODAL_OPEN',
                payload: {
                  component: project.ConfirmDialog,
                  props: { accept: false, needFile: false },
                },
              })
            )}
          </>
        );
    }

    return null;
  };

  const renderHeaderType = (header: NotificationData, body: Record<string, unknown>) => {
    if (body.estado === 'ANC') {
      return (
        t2(`project.execution.status.ANC`) + ' ' + t('dash') + ' ' + t2(`${header.type}`)
      );
    } else if (body.tipo === 'servico') {
      return t2(`${body.tipo}`);
    } else if (body.tipo === 'alteracao-pt') {
      return t2(`project.${body.aditivo ? 'aditive' : 'alteration-pt'}`);
    }

    return t2(header.type);
  };

  const renderContent = () => {
    if (error?.key === 'content') {
      return <ErrorMessage />;
    }

    if (status === 'idle' && notifications?.length && !selected) {
      return (
        <div className={styles.banner}>
          <img src={banner} alt="banner" />
          <span>{t1('banner')}</span>
        </div>
      );
    }

    return (
      <Data data={content} loading={status.startsWith('loading')} showLoader={!!selected}>
        {({ header, body }) => (
          <>
            <div className={styles.header}>
              <div className={styles.desc}>
                <span>{header.subtitle}</span>
                <span>{renderHeaderType(header, body)}</span>
              </div>

              <div className={styles.controls}>{renderControls({ header, body })}</div>
            </div>
            <div className={styles.body}>
              <ScrollPanel vBar={{ overlay: true }} hBar={{ visible: false }}>
                <div className={styles.wrapper}>
                  {renderComponent(view[header.type], {
                    data: body,
                    solicitation: header,
                  })}
                </div>
              </ScrollPanel>
            </div>
          </>
        )}
      </Data>
    );
  };

  return (
    <div className={styles.content}>
      {renderContent()}

      {modal &&
        renderComponent(modal.component, {
          ...modal.props,
          data: selected,
          onClose: (processed?: boolean) => {
            dispatch({ type: 'RELOAD', payload: processed });
            if ((modal?.props as any)?.reload) (modal?.props as any)?.reload(selected);
          },
        })}
    </div>
  );
};

export default Content;
