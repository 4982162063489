import React, { useRef, useState, useEffect, useMemo } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useTranslation } from 'i18n';
import { FieldMetadata } from 'components/form/types';
import { classes } from 'utils/components';
import { getKey, splitName } from 'utils/file';
import { call } from 'utils';
import { readAttr } from 'utils/object';
import ErrorDialog from './ErrorDialog';
import { Check, PDF, Upload, XAlt } from 'components/icons';
import { useForm } from 'components/form';
import { isEqual } from 'lodash';
import IconButton from '../IconButton/IconButton';
import Button from '../Button/Button';
import { appendDots } from 'utils/stringUtils';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';

import styles from './FileUploader.module.scss';

export type Props = {
  value?: File[];
  message?: string;
  metadata?: FieldMetadata;
  onChange?: (value: File[]) => void;
  onFileUpload?: (file: File[]) => void;
  label?: string;
  type?: 'classic' | 'sneaky' | 'button';
  hasFilesUploaded?: boolean;
  showUploaded?: boolean;
  clearAfterUpload?: boolean;
};

type Rules = {
  maxfiles: number;
  filesize: number;
  accept: string[];
};

const FileUploader: React.FC<Props> = props => {
  const form = useForm();

  const {
    value,
    message,
    metadata,
    onChange,
    type,
    label,
    hasFilesUploaded = false,
    showUploaded = true,
    onFileUpload,
    clearAfterUpload = false,
  } = props;

  const [files, setFiles] = useState<File[]>(value || []);
  const [highlight, setHighlight] = useState(false);
  const [errors, setErrors] = useState<Set<string>>();

  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const lastFilesRef = useRef<File[]>();

  const { t } = useTranslation();

  const rules: Rules = readAttr('rules', metadata, {});
  const { maxfiles = 0, accept = [], filesize = 0 } = rules;
  const enabled = maxfiles <= 0 || maxfiles > files.length;

  const required = readAttr('rules.required', metadata, false);
  const showOptional = useMemo(
    () => readAttr('rules.required', metadata) !== undefined && form?.display?.optional,
    [form.display.optional, metadata]
  );

  useEffect(() => {
    if (!isEqual(files, lastFilesRef.current)) {
      call(onChange, files);
    }
    lastFilesRef.current = files;
  }, [files, onChange]);

  const append = (list: File[], files: FileList) => {
    const newList = [...list];
    const errors = new Set<string>();

    for (let i = 0; i < files.length; ++i) {
      if (maxfiles && newList.length >= maxfiles) {
        errors.add('maxfiles');
        break;
      }

      const file = files.item(i);

      if (file) {
        if (accept.length && !accept.includes(splitName(file.name).ext)) {
          errors.add('accept');
        } else if (filesize && file.size > filesize) {
          errors.add('filesize');
        } else if (!containsFile(newList, file)) {
          newList.push(file);
        }
      }
    }

    if (errors.size) {
      setErrors(errors);
    }

    onFileUpload && onFileUpload(newList);

    return newList;
  };

  const onFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files; // important

    if (files) {
      setFiles(state => append(state, files));
    }
    clearAfterUpload && setFiles([]);
  };

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();

    if (enabled && !highlight) {
      setHighlight(true);
    }
  };

  const onDragLeave = (event: React.DragEvent) => {
    if (listRef.current) {
      const { clientX: x, clientY: y } = event;
      const r = listRef.current.getBoundingClientRect();

      if (x < r.left || x > r.right || y < r.top || y > r.bottom) {
        setHighlight(false);
      }
    }
  };

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault();

    if (enabled) {
      const files = event.dataTransfer.files;
      setFiles(state => append(state, files));
      setHighlight(false);
    }
  };

  const Label = (): JSX.Element => {
    return (
      <label className={styles.label}>
        {(label ? label : '') + ' '}
        {required ? (
          <span className={styles.required}>*</span>
        ) : (
          showOptional && (
            <span className={styles.optional}>
              {`(${t('components.form.field.optional')})`}
            </span>
          )
        )}
      </label>
    );
  };

  const Input = (): JSX.Element => {
    const style = {
      button: {
        color: 'rgb(51, 51, 51)',
        fontWeight: '700',
        opacity: 1,
      },
      default: {
        color: '#868686',
        fontWeight: 'normal',
        opacity: 1,
      },
    };

    const inputStyles = type === 'button' ? style.button : style.default;

    return (
      <>
        {hasFilesUploaded && (
          <div className={styles.checkIcon}>
            <Check color="#2F9E41" />
          </div>
        )}
        <input
          ref={inputRef}
          type="file"
          multiple
          accept={accept.map(item => `.${item}`).join(',')}
          value=""
          onChange={onFilesAdded}
        />
        <div
          className={styles.icon}
          style={{
            color: inputStyles.color,
          }}
        >
          <Upload />
        </div>
        <p
          style={{
            color: inputStyles.color,
            opacity: inputStyles.opacity,
            fontWeight: inputStyles.fontWeight,
          }}
        >
          {message}
        </p>
      </>
    );
  };

  const handleFiles = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const removeFile = (file: File) => {
    setFiles(state => state.filter(item => item !== file));
  };

  const UploadedFile = ({ file }: { file: File }) => {
    return (
      <div className={styles.uploadedItem}>
        <div className={styles.icon}>
          <PDF color="#2F9E41" />
        </div>
        <Tippy
          content={file.name}
          className={styles.popup}
          animation="perspective"
          touch={false}
          followCursor="horizontal"
          plugins={[followCursor]}
        >
          <span>{appendDots(file.name, 30)}</span>
        </Tippy>
        <IconButton
          icon={XAlt}
          color="#BF0003"
          size={25}
          onClick={() => removeFile(file)}
        />
      </div>
    );
  };

  if (type === 'button') {
    return (
      <div className={styles.buttonFile}>
        <Button
          tooltip={false}
          style={{ root: { backgroundColor: '#fff' }, text: { color: '#333333' } }}
        >
          <div className={styles.fileUploader}>
            <label className={styles.label}>{label}</label>

            <div
              className={styles.buttonFileList}
              ref={listRef}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              onDrop={onDrop}
            >
              <div
                className={classes(styles.fileInput, styles[type || 'classic'])}
                onClick={handleFiles}
              >
                <Input />
              </div>
            </div>

            <ErrorDialog
              errors={errors}
              rules={rules}
              onClose={() => setErrors(undefined)}
            />
          </div>
        </Button>
      </div>
    );
  }

  const showInput = files.length < maxfiles;

  return (
    <div className={styles.fileUploader}>
      <Label />
      {showInput && (
        <div
          className={classes(
            styles.fileList,
            highlight ? styles.highlight : null,
            styles[type || 'classic']
          )}
          ref={listRef}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <TransitionGroup className={classes(styles.transition)}>
            <CSSTransition timeout={800}>
              <div
                className={classes(styles.fileInput, styles[type || 'classic'])}
                onClick={handleFiles}
              >
                <Input />
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      )}

      {showUploaded &&
        files.length > 0 &&
        files.map((file, idx) => (
          <div
            className={classes(
              styles.fileList,
              highlight ? styles.highlight : null,
              styles[type || 'classic']
            )}
            ref={listRef}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            key={idx}
          >
            <TransitionGroup className={classes(styles.transition)}>
              <CSSTransition timeout={800}>
                <div
                  className={classes(styles.fileInput, styles[type || 'classic'])}
                  onClick={handleFiles}
                >
                  <UploadedFile file={file} />
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        ))}

      <ErrorDialog errors={errors} rules={rules} onClose={() => setErrors(undefined)} />
    </div>
  );
};

function containsFile(list: File[], file: File) {
  const key = getKey(file);

  for (let i = 0; i < list.length; ++i) {
    if (key === getKey(list[i])) {
      return true;
    }
  }
}

export default FileUploader;
